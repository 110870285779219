<template>
  <div class="wrp-desc">
	<div class="stol">
		<div id="column1" class="column column1" style="background:#ccc">
			<div class="column-title">Холодильник</div>
			<div class="area-tasks">
				<tasks/>
			</div>
		</div>
		<div id="column2" class="column column2" style="background:#ddd">
			<div class="column-title">Спринт</div>
			<div class="area-tasks">
				<tasks/>
			</div>
		</div>
		<div id="column3" class="column column3" style="background:#bbb">
			<div class="column-title">В работе</div>
			<div class="area-tasks">
				<tasks/>
			</div>
		</div>
		<div id="column4" class="column column4" style="background:#eee">
			<div class="column-title">Тест</div>
			<div class="area-tasks">
				<tasks/>
			</div>
		</div>
		<div id="column5" class="column column5" style="background:#aaa">
			<div class="column-title">Готово</div>
			<div class="area-tasks">
				<tasks/>
			</div>
		</div>
	</div>
  </div>
</template>

<script>
import Tasks from '../components/Tasks'

export default {
  components:{
	Tasks
  },
  name: "Desc",
  props: {
    msg: String
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	
</style>
