<template>
  <div>
  <!--
	<div style="text-align:center">
		<video ref="video3" width="480" controls @ended="end" @pause.prevent="ppp" @timeupdate.stop.prevent="ttt" >
			<source src="@/assets/demo234.mp4" type="video/mp4">
			Your browser doesn't support HTML5 video tag.
		</video>
	</div>
	-->
	<div style="margin:20px 50px">
		<video ref="video2" @timeupdate="progressUpdate">
			<source src="@/assets/demo234.mp4" type="video/mp4">
		</video>
				
		<div style="margin-top:-10px">
			<div class="barContainer">
				<div id="durationBar">
					<div ref="positionBar" id="positionBar"><span ref="displayStatus" id="displayStatus">0</span></div>
				</div>
			</div>
			<div>Громкость: {{volume}}</div>
			<button @click="play()">Play</button>
			<button @click="pause()">Pause</button>
			<button @click="stop()">Stop</button>
			<button @click="speedUp()">Быстрей</button>
			<button @click="slowDown()">Медленее</button>
			<button @click="normalSpeed()">Нормальная скорость</button>
			
			<button @click="volumeUp()">Громче</button>
			<button @click="volumeDown()">Тише</button>
			<button @click="mute()">Без звука</button>
		</div>
	</div>
	<table  style="background:#fff; margin:20px 50px"><tbody>
		<tr>
			<th>Название события</th>
			<th>Описание</th>
		</tr>
		<tr>
			<td><code>abort</code></td>
			<td>Отправляется, когда воспроизведение прерывается; Например, если воспроизведение медиа прерывается и начинается с самого начала, это событие будет отправлено.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/canplay" rel="nofollow" title="/ru/docs/Web/Reference/Events/canplay">canplay</a></code></code></td>
			<td>Отправляется, когда доступно достаточно данных для того, что бы медиа могло воспроизвестись, по крайней мере, в течение нескольких кадров.&nbsp;Соответствует состоянию (readyState) HAVE_ENOUGH_DATA.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/canplaythrough" rel="nofollow" title="/ru/docs/Web/Reference/Events/canplaythrough">canplaythrough</a></code></code></td>
			<td>Отправляется, когда состояние готовности изменяется к CAN_PLAY_THROUGH. Указывает, что медиа может быть полностью воспроизведено без перерыва, предполагая, что скорость загрузки остается, по крайней мере на нынешнем уровне. Примечание: Ручная установка CURRENTTIME вызовет событие canplaythrough в Firefox. В других браузерах это может не произойти.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/durationchange" rel="nofollow" title="/ru/docs/Web/Reference/Events/durationchange">durationchange</a></code></code></td>
			<td>Метаданные были загружены или изменены, что указывает на изменение в продолжительности медиа. Может быть отправлено, например, когда медиа загружено достаточно для того, чтобы продолжительность уже была известна.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/emptied" rel="nofollow" title="/ru/docs/Web/Reference/Events/emptied">emptied</a></code></code></td>
			<td>Медиа стало пустым. Например, это событие отправляется, если медиа уже загружено (или частично заргужено), и метод <a class="internal" href="/En/XPCOM_Interface_Reference/NsIDOMHTMLMediaElement" title="en/nsIDOMHTMLMediaElement"><code>load()</code></a> был вызван что бы его перезагрузить.</td>
		</tr>
		<tr>
			<td>encrypted&nbsp;<span style="line-height: 1.5;"><span title="Это экспериментальное API, которое не должно использоваться в рабочем коде."><i class="icon-beaker"> </i></span></span></td>
			<td>The user agent has encountered initialization data in the media data.</td>
		</tr>
		<tr>
			<td><code>ended</code></td>
			<td>Отправляется, когда воспроизведение завершено.</td>
		</tr>
		<tr>
			<td><code>error</code></td>
			<td>Отправляется, когда произошла ошибка.&nbsp; Атрибут&nbsp;<code>error</code> медиа объекта содержит более подробную информацию об ошибке. Смотрите&nbsp;<a href="/en-US/docs/Web/Guide/HTML/Using_HTML5_audio_and_video#Error_handling">Error handling</a>, что бы узнать подробнее.</td>
		</tr>
		<tr>
			<td><code>interruptbegin</code></td>
			<td>Отправляется, когда воспроизведение на Firefox OS устройстве прервано по любой из причин - например, если приложение ушло в фон или аудио с большим приоритетом начало воспроизведение. См. <a href="/en-US/docs/Web/API/AudioChannels_API/Using_the_AudioChannels_API">Using the AudioChannels API</a>.</td>
		</tr>
		<tr>
			<td><code>interruptend</code></td>
			<td>Отправляется, когда ранее прерванное воспроизвдеение на Firefox OS устройстве продолжает воспроизведение. См.&nbsp;<a href="/en-US/docs/Web/API/AudioChannels_API/Using_the_AudioChannels_API">Using the AudioChannels API</a></td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/loadeddata" rel="nofollow" title="/ru/docs/Web/Reference/Events/loadeddata">loadeddata</a></code></code></td>
			<td>Завершена загрузка первого кадра (frame) медиа.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/loadedmetadata" rel="nofollow" title="/ru/docs/Web/Reference/Events/loadedmetadata">loadedmetadata</a></code></code></td>
			<td>Метаданные медиа были загружены; все атрибуты теперь содержат максимум информации.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/loadstart" rel="nofollow" title="/ru/docs/Web/Reference/Events/loadstart">loadstart</a></code></code></td>
			<td>Отправляется, когда начинается загрузка медиа.</td>
		</tr>
		<tr>
			<td><code>mozaudioavailable</code></td>
			<td>Sent when an audio buffer is provided to the audio layer for processing; the buffer contains raw audio samples that may or may not already have been played by the time you receive the event.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/pause" rel="nofollow" title="/ru/docs/Web/Reference/Events/pause">pause</a></code></code></td>
			<td>Отправляется, когда воспроизведение приостановлено.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/play" rel="nofollow" title="/ru/docs/Web/Reference/Events/play">play</a></code></code></td>
			<td>Отправляется, когда воспроизведение медиа начинается после того, как было приостановлено;&nbsp;то есть, когда воспроизведение возобновляется после паузы.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/playing" rel="nofollow" title="/ru/docs/Web/Reference/Events/playing">playing</a></code></code></td>
			<td>Отправляется, когда начинается воспроизведение медиа (в первый раз, после паузы или после перезапуска).</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/progress" rel="nofollow" title="/ru/docs/Web/Reference/Events/progress">progress</a></code></code></td>
			<td>Отправляется периодически для информирования о прогрессе скачивания медиа файла. Информация об объеме загруженных данных доступна в атрибуте "buffered" элемента медиа.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/ratechange" rel="nofollow" title="/ru/docs/Web/Reference/Events/ratechange">ratechange</a></code></code></td>
			<td>Отправляется, когда изменяется скорость воспроизведения.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/seeked" rel="nofollow" title="/ru/docs/Web/Reference/Events/seeked">seeked</a></code></code></td>
			<td>Отправляется, когда операция поиска завершена.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/seeking" rel="nofollow" title="/ru/docs/Web/Reference/Events/seeking">seeking</a></code></code></td>
			<td>Отправляется, когда начинается операция поиска.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/stalled" rel="nofollow" title="/ru/docs/Web/Reference/Events/stalled">stalled</a></code></code></td>
			<td>Sent when the user agent is trying to fetch media data, but data is unexpectedly not forthcoming.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/suspend" rel="nofollow" title="/ru/docs/Web/Reference/Events/suspend">suspend</a></code></code></td>
			<td>Отправляется, когда загрузка медиа приостановлена.&nbsp;Это может прозойти как потому, что загрузка была завершена, так и по другим причинам.</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/timeupdate" rel="nofollow" title="/ru/docs/Web/Reference/Events/timeupdate">timeupdate</a></code></code></td>
			<td>Отправляется, когда изменяется значение атрибута&nbsp;<code>currentTime.</code></td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/volumechange" rel="nofollow" title="/ru/docs/Web/Reference/Events/volumechange">volumechange</a></code></code></td>
			<td>Отправляется, когда изменяется громкость звука (также когда звук включен или выключен).</td>
		</tr>
		<tr>
			<td><code><code><a class="new" href="/ru/docs/Web/Reference/Events/waiting" rel="nofollow" title="/ru/docs/Web/Reference/Events/waiting">waiting</a></code></code></td>
			<td>Отправляется, когда операция (напр. воспроизведение) была отложена до завершение другой операции (напр. поиск).</td>
		</tr>
	</tbody></table>
  </div>
</template>

<script>
 
  export default {
 
    name: 'video2',
    computed: {
		
    },
    data () {
		return {
			video2: '',
			video3: '',
			volume: 1.0
		}
    },
	mounted(){
		this.video2 = this.$refs.video2;
		this.video3 = this.$refs.video3;
	},
	methods:{
	
		ppp(){
			this.video3.play();
		},
		ttt(){
			
		},
		end(){
			console.log("конец");
		},
	
		play() {
			this.video2.play();
		},
		pause() {
			this.video2.pause();
		},
		stop() {
			this.video2.pause();
			this.video2.currentTime = 0;
		},
		mute(){
			this.video2.volume = 0;
		},
		volumeUp(){
			let new_value = (this.volume + 0.1).toFixed(1);
			
			if(new_value < 1.0){
				this.video2.volume = new_value;
			}
		},
		volumeDown(){
			let new_value = (this.volume - 0.1).toFixed(1);
		
			if(new_value > 0){
				this.video2.volume = new_value;
			}
		},
		
		speedUp() {
			this.video2.play();
			this.video2.playbackRate = 2;
		},
		slowDown() {
			this.video2.play();
			this.video2.playbackRate = 0.5;
		},
		normalSpeed() {
			this.video2.play();
			this.video2.playbackRate = 1;
		},
		progressUpdate() {
			// Устанавливаем позицию воспроизведения
			var positionBar = this.$refs.positionBar;
			positionBar.style.width = (this.video2.currentTime / this.video2.duration * 100)  + "%";
			
			// Заполняем текстовую надпись текущим значением
			var displayStatus = this.$refs.displayStatus;
			displayStatus.innerHTML = (Math.round(this.video2.currentTime*100)/100) + " сек";
			
			
			this.volume = this.video2.volume;
		}
		
	}
  }
</script>

<style scoped>

code{ background:#fff; color:#bd359b; display:block; padding:0px 0px; }

td{padding:5px 15px; border-bottom:1px solid #333}

#positionBar {
   height: 30px;
   color: white;
   font-weight: bold;
   background: steelblue;
   text-align: center;
}

#positionBar span {
   display: inline-block;
   margin-top: 5px;
}

#durationBar {
   border: solid 1px black;
   width: 100%;
   margin-bottom: 5px;
}
</style>