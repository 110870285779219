import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Home.vue"
import InterfaceSet from './views/InterfaceSet.vue'
import InterfaceStat from './views/InterfaceStat.vue'
import InterfaceVideo from './views/InterfaceVideo.vue'

import ListArticles from './views/articles/ListArticles.vue'
import DetailArticles from './views/articles/DetailArticles.vue'

import Team from './components/Desc.vue'

import NotFoundComponent from './views/NotFoundComponent.vue'
import Login from './components/Login.vue'
import store from './store'
Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
		path: "/",
		name: "home",
		component: Home,
		beforeEnter: ifAuthenticated,
    },
    {
      path: '/settings',
      name: 'InterfaceSet',
      component: InterfaceSet,
      beforeEnter: ifAuthenticated,
    },
	{
      path: '/stat',
      name: 'InterfaceStat',
      component: InterfaceStat,
      beforeEnter: ifAuthenticated,
    },
	{
		path: '/about-video',
		name: 'InterfaceVideo',
		component: InterfaceVideo,
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/articles/:id',
		name: 'DetailArticles',
		component: DetailArticles,
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/articles',
		name: 'ListArticles',
		component: ListArticles,
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/team',
		name: 'Team',
		component: Team,
		beforeEnter: ifAuthenticated,
	},
    {
		path: '/login',
		name: 'Login',
		component: Login,
		beforeEnter: ifNotAuthenticated,
    },
	{ path: '*', component: NotFoundComponent }
  ]
});
