<template>
  <div class="gen-wrp">
	
	<h1 style="text-align:center; margin-bottom:40px; background:#0000003d; padding-bottom:5px">XXXII Летние олимпийские игры</h1>
	
	<div style="display:flex">
		<div class="block-table" style="margin-bottom:20px; min-height:auto; max-height:300px; width:50%">
			<div class="head" style="display:flex">
				<div style="flex:6">Страна</div>
				<div class="gold-medal" style="flex:1"></div>
				<div class="silver-medal" style="flex:1"></div>
				<div class="bronze-medal" style="flex:1; width"></div>
			</div>
			
			<template v-if="countriesMedal.length">
				<div class="wrp-scroll">
					<div class="line" v-for="item,index in countriesMedal" :key="item.id" style="display:flex">
						<div style="flex:6" @click="medalCountryAthletes(item.id)">{{index+1}}. {{item.name}}</div> 
						<div style="flex:1; text-align:center">{{item.gold}}</div> 
						<div style="flex:1; text-align:center">{{item.silver}}</div>
						<div style="flex:1; text-align:center">{{item.bronze}}</div>
					</div>
				</div>
			</template>
			<div v-else style="text-align: center">
				<b-spinner variant="warning" style="margin-right:10px;"></b-spinner>
				<b-spinner variant="secondary"  style="margin-right:10px;"></b-spinner>
				<b-spinner variant="danger"></b-spinner>
			</div>
		</div>

		<div v-if="listMedalCountryAthletes.length" class="block-table" style="margin-bottom:20px; min-height:auto; max-height:300px; width:50%">
			<div class="head" style="display:flex">
				<div style="flex:6">Призеры</div>
				<div style="flex:1">Медаль</div>
			</div>
			<div class="wrp-scroll">			
				<div  class="line" v-for="item,index in listMedalCountryAthletes" :key="item.id" style="display:flex;  align-items: center;">
					<div style="flex:6">
						<div>{{index+1}}. {{item.name}}</div>  
						<div style="font-size:12px">{{item.sport}}</div>
					</div>
					<div style="flex:1;">
						<div v-if="item.place == 1" class="gold-medal" style="margin:0px auto" ></div>
						<div v-else-if="item.place == 2" class="silver-medal" style="margin:0px auto" ></div>
						<div v-else-if="item.place == 3" class="bronze-medal" style="margin:0px auto" ></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	
	<div class="block-table" style="margin-bottom:20px; min-height:auto; max-height:300px;">
		<div style="display:flex">
			<div class="time" :class="{'active': item.active, 'now': item.now}" v-for="(item,index) in timeList" :key="index" @click="sportTimeSchedule(item.id)">
				{{item.name}}
			</div>
		</div>
		
		<template v-if="listTypesSportSheduleToday.length">
			<div class="stat">Комплектов наград: <span class="cnt">{{gold}}</span>  События: <span class="cnt">{{cnt}}</span></div>
			<div class="wrp-scroll">
				<div class="line-time" v-for="item in listTypesSportSheduleToday" :key="item.id">
					<span class="title-date">{{item.date_start}}</span>
					
					<a class="btn-athlet" @click="sportTimeAthlete(item.id, item.name_sport+'. '+item.name)"></a>
					
					<span>{{item.id}}.</span> <span class="title-one">{{item.name_sport}}</span> <span class="title-two">{{item.name}}</span> <span v-if="item.flag_final == 1" class="title-gold">МЕДАЛИ</span> 
				</div>
			</div>
		</template>
		<div v-else style="text-align: center">
			<b-spinner variant="warning" style="margin-right:10px;"></b-spinner>
			<b-spinner variant="secondary"  style="margin-right:10px;"></b-spinner>
			<b-spinner variant="danger"></b-spinner>
		</div>
	</div>
	
	
	<b-modal id="modal-1" :title="titleSportTime" hide-footer>  
		<template v-if="this.listAthletes.length">
			<div v-for="item in this.listAthletes" :key="item.id">
				{{item.place}}. {{item.athlete_name}} ({{item.country_name}}). {{item.score}}
			</div>
		</template>
		<template v-else>
			Данных нет
		</template>
	</b-modal>
	
	
	
	<div class="wrap-list-sport" :class="{'active' : activeSport > 0}">
		<div @click="detailSport(item.id, item.name)" class="card" v-for="item in maslo" :key="item.id">
			<span>{{item.name}}</span>
		</div>
	</div>
	<div class="wrp-menu-olymp" style="height:200px">
		<div v-if="activeSport > 0" class="block-table" >
			<h4>{{headerName}}</h4>
			<div class="wrp-list-type-sport">
				<div @click="scheduleGame(item.id)" v-for="item in listTypesSport" :key="item.id">
					<span>{{item.name}}</span>
					<span class="lbl-small" v-if="item.flag_command == 1">Команда</span>
				</div>
			</div>
		</div>
		
		<div v-if="listTypesSportShedule.length > 0" class="block-table2">
			<div class="type-sport-time" v-for="item in listTypesSportShedule" :key="item.id">
				<span v-if="item.score != 'null'" style="float:right">{{item.score}}</span>
				<span v-else style="float:right"> {{item.date_start}}</span> 
				{{item.name}}
			</div>
		</div>
	</div>
	
	<div v-if="domain == 'localhost'">
		<div @click="showhide" style="width:100%; height:30px;">
		
		
		</div>
	</div>
  </div>
</template>

<script>
  import axios from 'axios';
//  import {Swiper} from 'vue2-swiper'
//  import {AUTH_REQUEST} from '../store.js'
  
  export default {
//	components: {Swiper},
    name: 'OlympGeneral',
    data () {
      return {
		maslo: [],
		activeSport: 0,
		
		spinnerCountry: false,
		
		domain: location.hostname,
		
		headerName: '',
		listTypesSport: [],
		listTypesSportShedule: [],
		
		listTypesSportSheduleToday: [],
		gold: 0,
		cnt: 0,
		
		listAthletes: [],
		
		titleSportTime: 'Атлеты',
		
		countriesMedal: [],
		
		flag_now_day: false,
		
		listMedalCountryAthletes: [],
		
		timeList: [
			{
				id: '07-24',
				name: '1 день',
				active: false,
				now: false
			},
			{
				id: '07-25',
				name: '2 день',
				active: false,
				now: false
			},
			{
				id: '07-26',
				name: '3 день',
				active: false,
				now: false
			},
			{
				id: '07-27',
				name: '4 день',
				active: false,
				now: false
			},
			{
				id: '07-28',
				name: '5 день',
				active: false,
				now: false
			},
			{
				id: '07-29',
				name: '6 день',
				active: false,
				now: false
			},
			{
				id: '07-30',
				name: '7 день',
				active: false,
				now: false
			},
			{
				id: '07-31',
				name: '8 день',
				active: false,
				now: false
			},
			{
				id: '08-01',
				name: '9 день',
				active: false,
				now: false
			},
			{
				id: '08-02',
				name: '10 день',
				active: false,
				now: false
			},
			{
				id: '08-03',
				name: '11 день',
				active: false,
				now: false
			},
			{
				id: '08-04',
				name: '12 день',
				active: false,
				now: false
			},
			{
				id: '08-05',
				name: '13 день',
				active: false,
				now: false
			},
			{
				id: '08-06',
				name: '14 день',
				active: false,
				now: false
			},
			{
				id: '08-07',
				name: '15 день',
				active: false,
				now: false
			},
			{
				id: '08-08',
				name: '16 день',
				active: false,
				now: false
			},
		]
      }
    },
	mounted(){
		this.groupSport();
		
		this.sportTimeSchedule('');
		
		this.listCountriesMedal();
	},
    methods: {
		groupSport(){
			axios.get('https://admin.plus-ton.ru/api3/group-sport')
			.then((res) => {
				this.maslo = res.data;
				this.listTypesSportShedule = [];
			}).catch(()=>{});
		},
		detailSport(val, name){
			 axios.get('https://admin.plus-ton.ru/api3/sport-type-list?val='+val)
			.then((res) => {
				this.activeSport = val;
				this.headerName = name;
			
				this.listTypesSportShedule = [];
			
				this.listTypesSport = res.data;
			}).catch(()=>{});
		},
		scheduleGame(val){
			 axios.get('https://admin.plus-ton.ru/api3/sport-type-shedule?val='+val)
			.then((res) => {
				this.listTypesSportShedule = res.data;
			}).catch(()=>{});
		},
		sportTimeSchedule(day){
		
			if(day == ''){
				let now = new Date();
				day = ("0"+(parseInt(now.getMonth())+1)).slice(-2)+'-'+("0"+now.getDate()).slice(-2);
			}
			
			for (var i = 0; i < this.timeList.length; i++) {
				if (this.timeList[i].id === day) {
					this.timeList[i].active = true;
					if(this.flag_now_day == false){
						this.timeList[i].now = true;
						this.flag_now_day = true;
					}
				} else {
					this.timeList[i].active = false;
				}
			}
		
		
			 axios.get('https://admin.plus-ton.ru/api3/sport-time-schedule?day='+day)
			.then((res) => {
				this.listTypesSportSheduleToday = res.data.times;
				this.gold = res.data.gold
				this.cnt = res.data.times.length
			}).catch(()=>{});
		},
		sportTimeAthlete(val, name){
			this.titleSportTime = name;
			axios.get('https://admin.plus-ton.ru/api3/sport-time-athlete?time='+val)
			.then((res) => {
				this.listAthletes = res.data;
				
				this.$bvModal.show('modal-1');
			}).catch(()=>{});
			
			
		},
		listCountriesMedal(){
			axios.get('https://admin.plus-ton.ru/api3/list-countries-medal')
			.then((res) => {
				this.countriesMedal = res.data;
		
			}).catch(()=>{});
		},
		medalCountryAthletes(val){
			axios.get('https://admin.plus-ton.ru/api3/medal-country-athletes?val='+val)
			.then((res) => {
				this.listMedalCountryAthletes = res.data;
		
			}).catch(()=>{});
			
			
		},
		
		
		showhide(){
			
		}
	}
}
</script>

<style  scoped>
	.btn-athlet{
		width:14px;
		height:14px;
		background:#000;
		float:right;
		margin-right:10px;
		margin-top:3px;
		display:block;
	}
	
	.gold-medal{
		width: 16px;
		height: 16px;
		border-radius: 8px;
		border: 2px solid #febb07;
		background: linear-gradient(141.63deg,#ffe660 17.01%,#ffb800 85.1%),linear-gradient(141.63deg,#ffc860 17.01%,#f39d00 85.1%),linear-gradient(0deg,#ffb82e,#ffb82e),linear-gradient(0deg,#fa936a,#fa936a);
			background-position-x: 0%, 0%, 0%, 0%;
			background-position-y: 0%, 0%, 0%, 0%;
			background-repeat: repeat, repeat, repeat, repeat;
	}
	.silver-medal{
		width: 16px;
		height: 16px;
		border-radius: 8px;
		border: 2px solid #8b94a8;
		background: linear-gradient(141.63deg,#b3bbcb 17.01%,#8b94a8 85.1%),linear-gradient(0deg,#939cb0,#939cb0),linear-gradient(0deg,#fbb80d,#fbb80d);
			background-position-x: 0%, 0%, 0%;
			background-position-y: 0%, 0%, 0%;
			background-repeat: repeat, repeat, repeat;
	}
	.bronze-medal{
		width: 16px;
		height: 16px;
		border-radius: 8px;
		border: 2px solid #ce731f;
		background: linear-gradient(141.63deg,#ff9e45 17.01%,#ce731f 85.1%),linear-gradient(141.63deg,#fca138 17.01%,#ce7e1f 85.1%),linear-gradient(0deg,#d08a38,#d08a38),linear-gradient(0deg,#fbb80d,#fbb80d);
			background-position-x: 0%, 0%, 0%, 0%;
			background-position-y: 0%, 0%, 0%, 0%;
			background-repeat: repeat, repeat, repeat, repeat;
	}

	.gen-wrp{
		margin: 20px 20px 50px;
	}

	.wrap-list-sport{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		
		transition: all 0.5s;
	}
	.wrap-list-sport::after {
		content: "";
		width:74.9%;
	}
	
	.wrap-list-sport::after {
		content: "";
		width:74.9%;
	}
	
	.wrap-list-sport .card{
		width: 12%;
		margin-bottom:12px;
		padding:12px;
		height:74px;
		border: 1px solid rgba(0, 0, 0, 0.4);
		border-radius: 5px;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
		overflow:hidden;
		cursor:pointer;
		
		justify-content: center;
		text-align:center;
  
	}
	
	.wrap-list-sport.active{
		height:40px; overflow-y:hidden; overflow-x: auto; white-space: nowrap; display:block;
		scrollbar-color: #458245 #714826;
    scrollbar-width: thin;
	}
	.wrap-list-sport.active .card {
		display: inline-block;
		padding:3px 12px 0px;
		width:auto;
		height:40px;
		border-radius:0px
	}
	
	
	.wrp-menu-olymp{
		display: flex;
		flex-flow: row wrap;
		justify-content: left;
		max-height:200px;
	}
	
	.block-table{
		padding:12px;

		background:#fff;
		flex:1;
		border: 1px solid rgba(0, 0, 0, 0.4);
		border-radius: 5px;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
		
		max-height:200px;
		overflow-y: auto;
	}
	.block-table2{
		padding:12px;
		flex:2;
		max-height:200px;
		overflow-y: auto;
		background:#fff;
		border: 1px solid rgba(0, 0, 0, 0.4);
		border-radius: 5px;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
	}
	
	wrp-list-type-sport{
		transition: height 0.5s;
	}
	
	
	.lbl-small{
		color:green;
		font-size:12px;
		padding-left:12px;
	}
	
	.type-sport-time:hover{
		background:#bcdbca;;
	}
	
	
	.line-time{font-size:14px;}
	.line-time:hover{
		background:#aaa
	}
	
	.title-one{}
	.title-two{}
	.title-date{float:right}
	.title-gold{background: #f2bb15;
		color: #0c6a15;
		border-radius: 20px;
		padding: 1px 8px;
		box-shadow: 0px 1px 2px #95730f;
	}

	.wrp-scroll{
		max-height:200px;
		overflow-y: auto;
		
		scrollbar-color: #458245 #714826;     /* «цвет ползунка» «цвет полосы скроллбара» */
		scrollbar-width:  thin;
	}
	
	
	.block-table .time{padding:0px 5px; cursor:pointer; border-top:2px solid white; white-space: nowrap}
	.block-table .time:hover{background:#aaa}
	.block-table .time.active{background: #b5cdf1; cursor:default}
	.block-table .time.now{border-top:2px solid red}
	
	
	.stat{background: #b5cdf1; padding: 1px 12px; margin: 0px -12px;}
	
	.line:hover{background:#ddd}
	
	
	/*
	.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(10px);
  opacity: 0;
}
	*/
	
	.item-enter-active,
.item-leave-active {
  transition: height 0.5s;
}
.item-enter,
.item-leave-to {
  height: 0;
  margin: 0;
  border: 0;
}


/*
::-webkit-scrollbar-button {
background-image:url('');
background-repeat:no-repeat;
width:5px;
height:0px
}

::-webkit-scrollbar-track {
background-color:#ecedee
}

::-webkit-scrollbar-thumb {
-webkit-border-radius: 0px;
border-radius: 0px;
background-color:#6dc0c8;
}

::-webkit-scrollbar-thumb:hover{
background-color:#56999f;
}

::-webkit-resizer{
background-image:url('');
background-repeat:no-repeat;
width:4px;
height:0px
}

::-webkit-scrollbar{
width: 4px;
}
*/
.block-table2, .block-table{
	scrollbar-color: #458245 #714826;     /* «цвет ползунка» «цвет полосы скроллбара» */
    scrollbar-width:  thin;
}

html, body {
    scrollbar-color: #458245 #714826;     /* «цвет ползунка» «цвет полосы скроллбара» */
    scrollbar-width: thin;  /* толщина */
}
	
</style>
	