<template>
  <div>
	<InterfaceSet/>
  </div>
</template>

<script>
  import InterfaceSet from '../components/InterfaceSet'
  export default {
    components: {
		InterfaceSet
    },
    name: 'temp',
    computed: {
		
    },
    data () {
      return {}
    },
  }
</script>

<style scoped>

</style>