const mocks = {
  'login': { 'POST': { token: 'This-is-a-mocked-token' } },
  'user/me': { 'GET': { name: 'doggo', title: 'sir' } }
}

const apiCall = ({url, method}) => new Promise((resolve, reject) => { // , ...args
  setTimeout(() => {
    try {
      resolve(mocks[url][method || 'GET'])
   //   console.log(`Mocked '${url}' - ${method || 'GET'}`)
   //   console.log('response: ', mocks[url][method || 'GET'])
    } catch (err) {
      reject(new Error(err))
    }
  }, 1000)
})

export default apiCall