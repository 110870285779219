<template>
  <div class="interface-set">
  <b-container>
		Интерфейс статистики
	</b-container>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: "InterfaceStat",
  components: {

  },
  props:{

  },
  data() {
    return {
		
	}
  },
  created() {
		
  },
  computed: {
	
  },
  methods: {
    
  }
  
};
</script>

<style scoped>

</style>
