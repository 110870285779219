<template>
  <div>
	<b-navbar toggleable="sm" type="dark" variant="dark">
		<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav>
					<router-link class="nav-link" to="/"><i class="fa fa-th-large" aria-hidden="true"></i></router-link>
					
					<b-nav-item-dropdown type="dark" class="navbar-collapse" variant="dark" text="Доска" left>
						<a v-b-modal.modalProj class="nav-link" @click="addFormProject" ><span style="padding-right:5px;">Добавить</span><i style="vertical-align:middle" class="fa fa-plus" aria-hidden="true"></i></a>
				
						<router-link class="nav-link" to="/settings">Правила</router-link>
						<router-link class="nav-link" to="/stat">Статистика</router-link>
					</b-nav-item-dropdown>
					
					<b-nav-item-dropdown type="dark" variant="dark" text="Полезное" left>
						<router-link class="nav-link" to="/about-video">О видео</router-link>
					</b-nav-item-dropdown>
					<router-link class="nav-link" to="/articles">Статьи</router-link>
				</b-navbar-nav>

				<!-- Right aligned nav items -->
				<b-navbar-nav class="ml-auto">
					<b-nav-item-dropdown right>
						<template v-slot:button-content>
							<em>Я <i class="fa fa-user" aria-hidden="true"></i></em>
						</template>
						<b-dropdown-item href="#" v-if="isAuthenticated" @click="logout">
							<span>Выйти</span>
						</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
	</div>
</template>

<script>
  import { mapGetters} from 'vuex'   //mapState
  import { AUTH_LOGOUT } from '../store'

  export default {
    name: 'navigation',
    methods: {
		logout: function () {
			this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'))
		},
		addFormProject(){
			this.$emit('add-form-project');
		}
    },
    computed: {
		...mapGetters(['isAuthenticated', 'authStatus']),
		loading: function () {
			return this.authStatus === 'loading' && !this.isAuthenticated
        }
    },
  }
</script>

<style  scoped>
  a {
    color: white;
    text-decoration: none;
  }
  .navigation {
    display: block;
    color: white;
    align-items: center;
    background-color: #7ea0b3;
    padding: 5px;
    ul{
      display: flex;
      &:first-child{
        flex-grow: 1;
      }
      li {
        padding-right: 1em;
      }
    }
  }
  .brand {
    
    align-items: center;
  }
  
  .head-right{float:right; display:inline-block}
  
  .item-menu{padding: 0px 3px 2px; margin: 0px 7px;}
  
  .router-link-exact-active{}
  .logout:hover{cursor:pointer}
	
	.navbar{padding: 0rem 1rem;}
	
	
	
</style>