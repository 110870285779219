<template>
  <div class="interface-set">
	<b-container>
		<b-tabs content-class="mt-3">
			<b-tab title="Исполнители" active>
				<b-list-group>
					<b-list-group-item>Добавление сотрудника</b-list-group-item>
					<b-list-group-item>Добавление стороннего разработчика</b-list-group-item>
				</b-list-group>
			</b-tab>
			<b-tab title="Авто-правила">
				<b-list-group>
					<b-list-group-item>Автоматические правила создания задач</b-list-group-item>
				</b-list-group>
			</b-tab>
			<b-tab title="Настройка правил доски">
				<b-list-group>
					<b-list-group-item>Временной интервал снятия статистики</b-list-group-item>
				</b-list-group>
			</b-tab>
			<b-tab title="Настройка сбора статистики">
				<b-list-group>
					<b-list-group-item>Необходимая статистика</b-list-group-item>
				</b-list-group>
			</b-tab>
		</b-tabs>
	</b-container>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: "InterfaceSet",
  components: {

  },
  props:{
	selProj: Object,
	formModeChg: Object
  },
  data() {
    return {
		projects:[],
		flagFormTask: 0,
		selTask: {}
	}
  },
  created() {
		
  },
  computed: {
	
  },
  methods: {
    
  }
  
};
</script>

<style scoped>
	.interface-set{color:#000}
</style>
