<template>
  <div class="container">
	<h1 class="h1">{{description.name}}</h1>
	<code></code>
	<div v-html="description.description" class="description"></div>
  </div>
</template>

<script>
import axios from 'axios';
 
  export default {
    name: 'detailarticle',
    data () {
		return {
			description: {}
		}
    },
	created(){
		this.packArticles()
	},
	methods:{
		packArticles(){
			axios.post('http://admin.plus-ton.ru/api/page-article', {id: this.$route.params.id})
			.then((res) => {
				if(res.data.status == 1){
					this.description = res.data.page
				}
			}).catch(()=>{});
		}
	}
  }
</script>

<style>
	.h1{ color:#000; text-align:center}
	body code{ background:#555; color:#fff; display:block; padding:4px 8px; }
</style>