<template>
  <div id="app">
	<div class="wrap-panel" v-if="this.$store.getters.isAuthenticated">
		<navigation @add-form-project="addFormProject" />
		<div id="list_proj" class="">
			<customSelectOne @chg-mode="chgModeDesk" :modeMobil="modeMobil" @edit-element="editFormProject" @sel-element="activeProject" :items="projects" :selElem="selProject" />
		</div>
	</div>
    <div class="main-container">
		<template v-if="loader">
			<div class="text-center">
				<b-spinner label="Spinning"></b-spinner>
				<b-spinner type="grow" label="Spinning"></b-spinner>
				<b-spinner variant="primary" label="Spinning"></b-spinner>
				<b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
				<b-spinner variant="success" label="Spinning"></b-spinner>
				<b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
			</div>
		</template>
		<template v-else>
			<router-view @user-projs="userProjs"  @add-task="addTask" @chg-task-status="chgTaskStatus" :modeMobil="modeMobil" :formModeChg="formModeChg" :selProj="selProject" />
			<b-modal id="modalProj" :title=formTitle hide-footer>
				<formProjectAdd @add-element="addProject" @chg-element="editProject" :formModeChg="formModeChg.flagFormProject" :selProj="selProject" :nameSubmit="name_submit" />
			</b-modal>
		</template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Navigation from './components/Navigation.vue'
import CustomSelectOne from './components/crosspp/CustomSelectOne.vue'
import FormProjectAdd from './components/FormProjectAdd.vue'

import { USER_REQUEST } from './store'
export default {
  name: 'app',
  components: {
    Navigation,
	CustomSelectOne,
	FormProjectAdd
  },
  data: function () {
	return {
		loader:true,
		modeMobil: 0,
		formModeChg: {
			'flagFormProject':0
		},
		projects: [
			
		],
		selProject: {
			'id': 0, // 2
			'name': 'Нет проекта' // 'Второй проект'
		}
	}
  },
  created: function () {
    if (this.$store.getters.isAuthenticated) {
		this.$store.dispatch(USER_REQUEST)
		
		let val = this.$store.state.token;
		this.userProjs(val);
	} else {
		this.loader = !this.loader;	
	}
  },
  computed: {
	formTitle: function(){
		if(this.formModeChg.flagFormProject){
			return "Редактирование доски";
		} else {
			return "Добавление доски";
		}
	},
	name_submit: function(){
		if(this.formModeChg.flagFormProject){
			return "Сохранить проекта";
		} else {
			return "Плюс проект";
		}
	},
  },
  methods: {
	activeProject(selproject){
		let val = this.$store.state.token;
				
		axios.post('http://admin.plus-ton.ru/api/author-project2', {'val': val, 'val2': selproject.id})
			.then((res) => {
				this.selProject = res.data;
				
				console.log(this.selProject);
				
				localStorage.setItem('sel_project', JSON.stringify(res.data));
			})
			.catch(function(){
				
			});
	},
	userProjs(val){
		axios.post('http://admin.plus-ton.ru/api/author-projects', {'val': val})
			.then((res) => {
				this.projects = res.data;
				if(res.data[0]){
					let loc = JSON.parse(localStorage.getItem('sel_project'));
					if(loc){
						this.selProject = loc;
					} else {
						this.selProject = res.data[0];
					}
				} else {
					this.selProject = {
						'id': 0,
						'name': 'Нет проекта'
					}
				}
				
				this.loader = false;	
			})
			.catch(function(){
				
			});
	},
	addTask(task){
		this.selProject.taskRangeStatuses[0].push(task);
		localStorage.setItem('sel_project', JSON.stringify(this.selProject));
	},
	chgTaskStatus(taskId, oldStatus, newStatus){
		
		let i = 0;
		let temp = {};
		for( ; i < this.selProject.taskRangeStatuses[oldStatus].length; i++){
			if(this.selProject.taskRangeStatuses[oldStatus][i].id == taskId){
				temp = this.selProject.taskRangeStatuses[oldStatus][i];
				temp.status_proj = newStatus
				break;
			}
		}
		
		this.selProject.taskRangeStatuses[newStatus].push(temp);
		this.selProject.taskRangeStatuses[oldStatus].splice(i, 1);
		
		localStorage.setItem('sel_project', JSON.stringify(this.selProj));
	},
	addFormProject(){
		this.formModeChg.flagFormProject = 0;
	},
	chgModeDesk(){
		if(this.modeMobil == 1){
			this.modeMobil = 0;
		} else {
			this.modeMobil = 1;
		}
	},
	editFormProject(){
		this.formModeChg.flagFormProject = 1;
		this.$bvModal.show('modalProj');
	},
	addProject(newproject){
		this.$bvModal.hide('modalProj');
		localStorage.setItem('sel_project', JSON.stringify(newproject));
		this.projects.push(newproject);
		this.selProject = newproject;
	},
	editProject(editproject){
		this.$bvModal.hide('modalProj');
		localStorage.setItem('sel_project', JSON.stringify(editproject));
		let i = 0;
		for( ; i < this.projects.length; i++){
			if(this.projects[i].id == editproject.id){
				this.projects.splice(i, 1, editproject);
				break;
			}
		}
		
		this.selProject = editproject;
	}
  },
 
}
</script>

<style>
  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: #2e426b;
/*	background: linear-gradient(to left, #084269 0%, #7daccc 15%, #7daccc 85%, #084269 100%); */
	background:url('assets/fonfon.jpg') repeat center 0 !important;
	padding-top:34px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  h1{color:#fff}
  
  .box-box-box{transition: 1.5s all cubic-bezier(0.39, 0.575, 0.565, 1);}
  
	.navbar-nav .dropdown-menu{padding:0px}
	.navbar-nav .dropdown-menu, .navbar-nav .dropdown-menu.show{
		background: #526476;
		border-radius: 0px;
	}
	.navbar-nav .dropdown-menu a:hover{
		background: #8f9fb0;
	}
	
	.dropdown-menu.show{margin:0px;}
	
	.dropdown-menu.dropdown-menu-right.show .dropdown-item span{color:#fff}
	
</style>

<style scoped>
  .main-container {
  /*  min-height: calc(100vh - 70px); */
  }
  
  .main-container{padding-top: 7px}
  .wrap-panel{box-shadow: 0px 0px 2px #313f46; position: fixed; width: 100%; left: 0px; top: 0px; z-index:10;}
  
</style>
