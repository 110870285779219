<template>
  <div>
	<InterfaceStat/>
  </div>
</template>

<script>
  import InterfaceStat from '../components/InterfaceStat'
  export default {
    components: {
		InterfaceStat
    },
    name: 'stat',
    computed: {
		
    },
    data () {
      return {}
    },
  }
</script>

<style scoped>

</style>