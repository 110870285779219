<template>
  <div class="container">
    <slot/>
  </div>
</template>

<style scoped>
  .container {
	padding-bottom:0px;
  }
</style>


<script>
  export default {
    name: 'center-container',
  }
</script>