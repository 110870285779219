<template>
  <div class="crosspp-select-one" :class="{ active: classShowList}" v-if="items" v-bind:style="{marginLeft: mLeft + 'px' }" >
	<div id="sel-curr" @click.stop="switchList" class="sel-curr" >
		<span>{{selElem.name}}</span> <a class="icon-on-lite" href="#" @click.stop="editProj"><i class="fa fa-tasks" aria-hidden="true"></i></a> <a v-if="modeMobil == 1" class="icon-on-lite" href="#" @click.stop="chgMode"><i class="fa fa-columns" aria-hidden="true"></i></a><a v-else class="icon-on-lite" href="#" @click.stop="chgMode"><i class="fa fa-th" aria-hidden="true"></i></a>
		
		
	</div>
	<div id="sel-list" class="sel-list" v-if="items.length > 1">
		<div class="sel-list-item" v-for="item in items" :key=item.id >
			<a @click.prevent="selectElem(item.id, item.name)" href="#">{{item.name}}</a>
		</div>
	</div>
  </div>
</template>

<script>
  export default {
    name: 'CustomSelectOne',
	props: {
		items: Array,
		selElem: Object,
		modeMobil: Number
	},
	data: function () {
		return {
			classShowList: false
		}
    },
	computed: {
		mLeft: function(){
			return "-70";
		}
    },
	created: function () {
		var vm = this;
            document.addEventListener('click', function () {
             vm.classShowList = false;
            });
	},
	methods: {
		switchList: function () {
			this.classShowList = !this.classShowList;
		},
		
		selectElem: function (id, name) {
			this.$emit('sel-element', {'id': id, 'name': name});
			
			this.classShowList = false;
		},
		editProj(){
			this.$emit('edit-element');
		},
		chgMode(){
			this.$emit('chg-mode');
		}
    }
  }
</script>

<style scoped>
	.crosspp-select-one{position:absolute; left:50%; top:0px;}
	.crosspp-select-one.active{width:100%; left:0px; text-align:center; margin-left:0px !important;}
	.crosspp-select-one .sel-curr{padding: 7px 10px 7px; background: #d9d9d9; font-size: 18px; box-shadow: 0px 1px 2px #000; max-width:320px; text-align:center; display:inline-block; z-index:1; white-space:nowrap; overflow:hidden; transition:0.5s}
	.crosspp-select-one .sel-curr:hover{background:#eee; cursor:pointer; }
	.crosspp-select-one.active .sel-curr{border-radius:0px;}
	.crosspp-select-one .sel-list{position:relative; transition:0.5s; display:none; background: #d9d9d9; z-index:2; margin-top: -6px;}
	.crosspp-select-one.active .sel-list{display:block;}
	.sel-list-item a{text-decoration:none; color:#000; padding: 6px 10px 7px; background:#d9d9d9; display:block; transition:0.3s;}
	.sel-list-item a:hover{background:#aaa}
	
	@media screen and (max-width: 600px) {
		#list_proj{ }
		#list_proj .sel-curr{font-size:14px; width:100%;}
		.crosspp-select-one{left:auto !important; right:0px; width:50%;}
	}
   
   a.icon-on-lite{color:#000; transition:0.5s}
   a.icon-on-lite:hover{color:#b53a3a;}
</style>