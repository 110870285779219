<template>
  <div class="wrp-login-form">

	<template v-if="!flagReg">
		<form class="login" @submit.prevent="login" style="position:relative">
			<a v-if="flagLinkReg" href="#" @click="flagReg = 1" style="color:#fff;position: absolute; right: 10px; top: 0px;" >@Rегистрация <i class="fa fa-share" aria-hidden="true"></i></a>
			<h1 class="form-title">Вход</h1>
			<div v-if=warn class="alert alert-danger">
				{{warn}}
			</div>
			<div class="form-field">
				<input required v-model="user.username" type="text" placeholder="Ваше имя"/>
			</div>
			<div class="form-field">
				<input required v-model="user.password" type="password" placeholder="Ваш пароль"/>
			</div>
			<div class="form-field last">
				<button class="btn btn-primary" type="submit">Продолжить</button>
			</div>
		</form>
		<div class="container"><div style="margin-top:20px;" class="alert alert-info">Доступы к полноценному аккаунту [Логин: <a href="#" @click="setfield('demo87@t.ru',0)" >demo87@t.ru</a>  Пароль: <a href="#" @click="setfield('demo87', 1)" >demo87</a> ]</div></div>
	</template>
	<template v-else>
		<form class="login" @submit.prevent="reg" style="position:relative">
			<a href="#" @click="flagReg = 0" style="color:#fff;position: absolute; left: 10px; top: 0px;" ><i class="fa fa-reply" aria-hidden="true"></i> @Войти</a>
			<h1 class="form-title">Регистрация</h1>
			<div v-if=warn class="alert alert-danger">
				{{warn}}
			</div>
			<div class="form-field">
				<input required v-model="userreg.username" type="text" placeholder="Ваш логин"/>
			</div>
			<div class="form-field">
				<input required v-model="userreg.email" type="text" placeholder="эл. почта"/>
			</div>
			<div class="form-field">
				<input required v-model="userreg.password" type="password" placeholder="Ваш пароль"/>
			</div>
			<div class="form-field">
				<input required v-model="userreg.password_two" type="password" placeholder="Повторите пароль"/>
			</div>
			<div class="form-field last">
				<button class="btn btn-primary" type="submit">Занести</button>
			</div>
		</form>
	</template>
	
	<div>
		<OlympGeneral />
	</div>
	
  </div>
</template>

<script>
  import axios from 'axios';
  import OlympGeneral from '../components/olymp/OlympGeneral.vue'
//  import {AUTH_REQUEST} from '../store.js'
  
  export default {
    name: 'login',
	components: {
		OlympGeneral
	},
    data () {
      return {
		user:{
			username: '',
			password: ''
		},
		userreg:{
			username: '',
			password: '',
			password_two: '',
			email:''
		},
		warn: '',
		flagReg: 0,
		flagLinkReg: 0,
		
      }
    },
    methods: {
		setfield(text, numfield){
			if(numfield == 0){
				this.user.username = text;
			} else {
				this.user.password = text;
			}
		},
		login() {
			axios({
				method: 'post',
				url: 'http://admin.plus-ton.ru/api/login',
				responseType: 'json',
				data: this.user,
				headers: {
					'Content-type': 'application/json; charset=UTF-8',
				}
			}).then(response => {
				if(response.data.access_token){
					localStorage.setItem('user-token', response.data.access_token);
					this.$store.state.token = localStorage.getItem('user-token');
					
					this.userProj(this.$store.state.token);
					this.$router.push('/')
				} else {
					this.warn = response.data.error
				}
			}).catch(() => { // error
				localStorage.removeItem('user-token')
			});
			/*
			const { username, password } = this
			this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
			  this.$router.push('/')
			})
			*/
		},
		reg(){
			axios({
				method: 'post',
				url: 'http://admin.plus-ton.ru/api/reg',
				responseType: 'json',
				data: this.userreg,
				headers: {
					'Content-type': 'application/json; charset=UTF-8',
				}
			}).then(response => {
				if(response.data){
					
					console.log(response.data);
				//	this.$router.push('/')
				} else {
					this.warn = response.data.error
				}
			}).catch(() => { // error
			//	this.warn = response.data.error
			});
		},
		userProj(val){ this.$emit('user-projs', val) }
    },
  }
</script>


<style scope>
  .login {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 10px;
	background: #658e97;
	border-radius:0px 0px 6px 6px
  }
  .wrp-login-form form{margin:-58px auto 0}
  
  form .form-field{margin-bottom:20px; display: flex;
  flex-direction: column; }
  .form-field input{padding: 6px 7px 7px; height: auto; line-height: 16px; font-size: 16px; border-radius: 3px;}
  .form-field.last{margin-top:50px}
  
  .form-title{margin-bottom: 30px; margin-top: 30px;}
  
  
</style>