<template>
  <div style="min-height: calc(100vh - 70px);">
    <loading v-if="loading"/>

    <div style="min-height: calc(100vh - 70px);" v-if="isAuthenticated">
		<template v-if="isProfile">
			<template v-if="modeMobil == 1">
				<InterfaceOne :formModeChg="formModeChg" :selProj="selProj" @add-task="addTask" @chg-task-status="chgTaskStatus" />
			</template>
			<template v-else>
				<InterfaceOneColumn :formModeChg="formModeChg" :selProj="selProj" @add-task="addTask" @chg-task-status="chgTaskStatus" />
			</template>
		</template>
		<template v-else>
			<team/>
		</template>
    </div>
    <div v-if="!isAuthenticated && authStatus !== 'loading'">
      <login/>
    </div>
  </div>
</template>

<script>
//  import axios from 'axios';
  import { mapGetters } from 'vuex'
  import Login from '../components/Login'
  import InterfaceOne from '../components/InterfaceOne'
  import InterfaceOneColumn from '../components/InterfaceOneColumn'
  
//  import { USER_REQUEST } from '../store'
  export default {
    components: {
		Login,
		InterfaceOne,
		InterfaceOneColumn
    },
	props:{
		selProj:Object,
		formModeChg: Object,
		modeMobil: Number
	},
    name: 'home',
    computed: {
      ...mapGetters(['isAuthenticated', 'authStatus', 'isProfile']),
      loading: function () {
        return this.authStatus === 'loading' && !this.isAuthenticated
      }
    },
	created() {

    },
    data () {
		return {
			
		}
    },
	methods: {
		addTask(task){ this.$emit('add-task', task)},
		chgTaskStatus(taskId, oldStatus, newStatus){ this.$emit('chg-task-status', taskId, oldStatus, newStatus)}
	}
  }
</script>

<style scope>
  .home {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
</style>