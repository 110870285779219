<template>
	<div>
		<div style="overflow:hidden">
			<div style="float:right">
				<input type="text" v-model="newItem" style="width:120px;" />
				<a @click.prevent="pushLists" class="btn btn-info" style="padding: 2px 8px; vertical-align: top; border-radius: 0px 6px 6px 0px;" href="#"><i class="fa fa-plus" aria-hidden="true"></i></a>
			</div>
			{{title}}:
		</div>
		<draggable style="margin: 7px 0px"
			:list="items"
			:disabled="!enabled"
			class="listStatuses"
			ghost-class="ghost"
			:move="checkMove"
			@start="dragging = true"
			@end="dragging = false"
		>
			<div class="field" v-for="(item,index) in lists" :index=index :key="index">
				<a href="#" @click.prevent="delList(index)" class="close-field"><i class="fa fa-times" aria-hidden="true"></i></a>
				{{ item.name }}
			</div>
		</draggable>
	</div>
</template>

<script>
import draggable from "vuedraggable";
  export default {
    name: 'InputListSort',
	components: {
      draggable
    },
	props: {
		lists: Array,
		title: String
	},
	data: function () {
		return {
			newItem: '',
			items: [],
			cnt: 0,
			enabled: true,
			dragging: false
		}
    },
	created: function () {
		this.items = this.lists;
		this.cnt = this.lists.length;
	},
	methods: {
		switchList: function () {
		
		},
		pushLists(){
			if(this.newItem != ''){
				let cnt = this.cnt += 1;
				this.items.push({id: cnt, name: this.newItem});
				this.newItem = '';
			}
		},
		delList(ind){
			this.items.splice(ind, 1);
		},
		checkMove: function() {
			
		}
    }
  }
</script>

<style scoped>
	.listStatuses{}
	.listStatuses div{display: inline-block; padding: 0px 7px 2px; background:#ddd; border: 1px solid #aaa; margin-right: 2px;}
	div.btn-add{border:none; background:none;}
	.listStatuses div a{ font-size:20px; line-height:20px; font-weight:bold}
	.listStatuses div a:hover{text-decoration:none;}
	
	.listStatuses div.field{position:relative}
	.listStatuses div.field .close-field{float: right; font-size: 12px; color: red; line-height: 24px; margin-left: 7px;}
	
	.listStatuses div.field:hover{cursor:pointer}

</style>