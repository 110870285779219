<template>
  <div class="interface-one" style="margin-bottom:50px;">
	<div v-if="selProj.id > 0">	
		<div v-if="selProj.taskRangeStatuses.length">
			<div class="status-mode" v-for="(packTask,index) in selProj.taskRangeStatuses" :key="index">
				<div class="column-head">{{selProj.listStatuses[index].name}} <a v-if="index == 0" @click="formAddTask" v-b-modal.modalTask class="btn-text-right" style="margin-left:12px" > <i style="vertical-align:middle" class="fa fa-plus" aria-hidden="true"></i><span style="padding-left:5px">задачa</span></a>
				</div>
				
				<div class="body-pack-tasks">
					<div class="wrp-task-card" :class="{ 'empty-task-desc': task.id == 0 }" v-for="task in packTask" :key="task.id">
						<template v-if="task.id == 0">
							<div style="display:none; position: absolute; top: 0px; left: 0px;"></div>
						</template>
						<template v-else>
							<div class="task-card-panel" >
								<b-dropdown size="sm" class="act-menu-task">
									<template v-slot:button-content>
										<i class="fa fa-bars" aria-hidden="true"></i>
									</template>
									<b-dropdown-item v-if="selProj.listStatuses.length-1 > index" href="#" @click="editFormTask(task)" ><i class="fa fa-pencil" aria-hidden="true"></i></b-dropdown-item>
									<b-dropdown-item href="#" @click="showTask" ><i class="fa fa-eye" aria-hidden="true"></i></b-dropdown-item>
								</b-dropdown>
								<div v-if="task.type_id > 0"><div class="stick-task" :style="{background: task.color, color: '#000'}" title="Тип задачи">{{task.type_id == 1 ? "C" : "A"}}</div> {{task.fromDate}}</div>
							</div>
							<div class="task-card-content">
								<div style="overflow: hidden; height: 86px;">{{task.descr}}</div>
							</div>
							<div class="task-card-info">
								<div class="icons" style="float:right" title="план-часы">
									<i aria-hidden="true" class="fa fa-clock-o"></i>
									<div>{{task.time_plan}}</div>
								</div>
								<div class="icons" style="float:left" title="сложность">
									<i aria-hidden="true" class="fa fa-bolt"></i>
									<div>{{task.slozhnost}}</div>
								</div>
								
								<div class="number-stick">
									<div class="wrp-photo"><div class="numb">{{task.number}}</div> <i class="fa fa-user" aria-hidden="true"></i></div>
									<br />
									<a @click="changeStatusNext(task)" v-if="selProj.listStatuses.length-1 > index" class="task-next-status" href="javascript:void(0)">
										<span style="font-size:11px; text-transform: lowercase; text-transform: lowercase; font-size: 11px; position: absolute; left: 50%; margin-left: -17%;">Толкнуть</span>
										<i class="fa fa-sort-desc" aria-hidden="true"></i>
										<i class="fa fa-sort-desc" aria-hidden="true"></i>
										<i class="fa fa-sort-desc" aria-hidden="true"></i>
									</a>
								</div> 
							</div>
						</template>
					</div>
					
				</div>
			</div>
		</div>
		<div v-else>
			<div class="alert alert-danger">У проекта не указаны статусы</div>
		</div>
	</div>
	<div v-else style="text-align:center">
		<a v-b-modal.modalProj class="btn btn-success" >Добавить проект</a>
	</div>
	
	<b-modal id="modalTask" size="lg" :title=formTitleTask hide-footer>
		<formTaskAdd @add-task="addTask" @chg-task="editTask" :selTask="selTask" :linkid="selProj.id" :formModeChg="flagFormTask" :nameSubmit="name_submit_task" />
	</b-modal>
  </div>
</template>

<script>
 import axios from 'axios';
 import FormTaskAdd from '../components/FormTaskAdd.vue'

export default {
  name: "InterfaceOne",
  components: {
	FormTaskAdd
  },
  props:{
	selProj: Object,
	formModeChg: Object
  },
  data() {
    return {
		projects:[],
		flagFormTask: 0,
		selTask: {}
	}
  },
  created() {
	
  },
  computed: {
	tasksShare: function(){
		let l_statuses = this.selProj.listStatuses.length;
		var res = Array(l_statuses);
		
		let i = 0;
		for( ; i < this.selProj.listStatuses.length; i++){
			res[i] = [];
		}
		
		i = 0;
		for( ; i < this.selProj.tasks.length; i++){
			let ind = this.selProj.tasks[i].status_proj;
			if(res[ind]){
				res[ind].push(this.selProj.tasks[i]);
			} else {
				res[l_statuses - 1].push(this.selProj.tasks[i]);
			}
		}
		
		res[res.length - 1] = res[res.length - 1].slice().reverse();
		
		return res;
	},
	formTitleTask: function(){
		if(this.flagFormTask){
			return "Редактирование задачи";
		} else {
			return "Добавление задачи";
		}
	},
	name_submit_task: function(){
		if(this.flagFormTask){
			return "Сохранить изменения";
		} else {
			return "Плюс задача";
		}
	}
  },
  methods: {
	addTask(task){ this.$emit('add-task', task); this.$bvModal.hide('modalTask');},
	editTask(){ this.$bvModal.hide('modalTask');},
	
	formAddTask(){
		this.flagFormTask = 0;
	},
	editFormTask(task){
		this.flagFormTask = 1;
		this.$bvModal.show('modalTask');
		
		this.selTask = task;
	},
	showTask(){
		console.log("Показать задачу");
	},
	shareTasksForStatuses(){
		
	},
	changeStatusNext(task){
		var newStatus = task.status_proj+1;
		axios.post('http://admin.plus-ton.ru/api/task-chg-status', {'val':task.id, 'status': newStatus})
		.then((res) => {
			if(res.data.status == 1){
				this.$emit('chg-task-status', task.id, task.status_proj, newStatus);
			} else {
				alert('ошибка смены статуса');
			}
		}).catch(()=>{});
	}
	
	
	
  /*
	chgGetHist: function(history){
	
		let i = 0;
		for( ; i < this.histories.length; i++){
			if(this.histories[i].id == history.id){
				this.histories.splice(i, 1, history);
				break;
			}
		}
		i = 0;
		for( ; i < this.projHistories.length; i++){
			if(this.projHistories[i].id == history.id){
				this.projHistories.splice(i, 1, history);
				break;
			}
		}
	
		this.$bvModal.hide('modalhistory');
	},
	addHist: function(){
		this.hist = {
			name : '',
			project_id : this.selproject
		};
		this.formTitle = "Добавление истории";
		this.name_submit = "Добавить";
	},
	closeHist: function(histid){
		var i = 0;
		for( ; i < this.histories.length; i++){
			if(this.histories[i].id == histid){
				this.histories.splice(i, 1);
				break;
			}
		}
		i = 0;
		for( ; i < this.projHistories.length; i++){
			if(this.projHistories[i].id == histid){
				this.projHistories.splice(i, 1);
				break;
			}
		}
	},
	changeHist: function(histid){		
		axios.defaults.headers.common['Authorization'] = this.$store.state.token;
		axios
			.get('http://scrum.wilkins.ru/api/history-get?val='+histid)
			.then(response => {
				console.log(response.data);
				this.hist = response.data;
				this.formTitle = "Изменение истории";
				this.name_submit = "Изменить";
			})
			.catch(() => {
		
			});
	},
	moveEnd: function(){ // evt
		let data = this.histories;
		
		axios.defaults.headers.common['Authorization'] = this.$store.state.token;
		axios.post('http://scrum.wilkins.ru/api/set-priority', data)
          .then(function() {
          })
		  .catch(() => {

		  });
	},
	getAllHistories(){
		axios.defaults.headers.common['Authorization'] = this.$store.state.token;
		axios
			.get('http://scrum.wilkins.ru/api/list-history?val=0')
			.then(response => {
				this.histories = response.data.allHistory;
			})
			.catch(error => {
				this.status = error.response.data.status;
			});
	},
	getAllProjects(){
		axios
			.get('http://scrum.wilkins.ru/api/get-projects')
			.then(response => { 
			//	this.projects = Object.values(response.data).map(i => Object.values(i) )
				this.projects = response.data;
			});
	},
	getProjectHistories(id){
		axios
			.get('http://scrum.wilkins.ru/api/list-history?val='+id)
			.then(response => { 
				this.projHistories = response.data.allHistory;
			});
	},
	minimize(){
		if(this.btnmin_flag == 1){
			this.btnmin_flag = 0;
			this.activeminihistory = 'mini-history';
		} else {
			this.btnmin_flag = 1;
			this.activeminihistory = '';
		}
	},
	
	*/
  }
  
};
</script>

<style scoped>
	.interface-one{color:#fff}

	.status-mode{height:200px; border-left: 10px solid #596877; padding-left: 12px; margin:8px 0px;}
	
	.body-pack-tasks{white-space: nowrap; overflow-y:hidden; overflow-x: auto;}
	
	.wrp-task-card{position:relative; display:inline-block; vertical-align:top; width: 150px; height: 150px; background: linear-gradient(to right, #F6EFD2, #CEAD78); color: #000; border-radius: 3px; margin-right:8px;}
	.wrp-task-card:hover{box-shadow:0px 0px 3px #333;}
	
	.task-card-content{white-space:normal; padding:12px; height: 98px; /* overflow: hidden; */ line-height: 14px; font-size: 12px;}
	.task-card-info{position:absolute; left:0px; bottom:0px; width:100%}
	
	.task-card-info .icons{padding: 0px 8px; text-align:center}

	.number-stick{ /* position:absolute; left:0px; bottom:0px;  width:100%; */ text-align:center;}
	.task-next-status{display:block; margin-left: 48px; margin-right: 48px;}
	
	.column-head{margin-bottom:12px; background: #596877; margin-left: -12px; padding: 1px 5px 5px 13px;}
	
	.btn-text-right{color:#efefef !important}
	.btn-text-right:hover{cursor:pointer;}
	.btn-text-right{margin-left:12px}
	
	.act-menu-task{float:right; z-index:6;  padding: 0px; line-height:1.2}
	.act-menu-task button{padding: 0px 4px; line-height: 1.6;} 
	
	.dropdown-menu.show{margin:0px}
	
	/* .wrp-task-card:hover .act-menu-task button{display:block !important}*/
	
	.wrp-photo{position: absolute; left: 50%; margin-left: -27px; top: -25px;  width: 50px;}
	.wrp-photo .numb{position: absolute; bottom: 0px; left: 0px; width: 100%; height: 16px; background: #0000009e; color: #fff; line-height: 15px;}
	.wrp-photo i{line-height: 50px; font-size: 45px;}
	
	.task-card-content:hover{z-index:3; position:relative; background:#fff}
	
	.dropdown-menu.show{z-index:2; margin:0px}
	
	.task-card-panel .dropdown-menu, .task-card-panel .dropdown-item{padding:0px; }
	.task-card-panel .dropdown-item{padding: 4px 8px;}
	.task-card-panel .dropdown-item:hover{background:#ccc}
	.task-card-panel .dropdown-menu{min-width:auto}
	
	.stick-task{background: rgb(204, 217, 227) none repeat scroll 0% 0%; float: left; padding: 0px 8px; margin-right: 4px; border-radius: 0 0 12px 0;}
	
	.empty-task-desc{margin:0px; display:none}
</style>
