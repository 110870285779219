<template>
  <div>
	404 страница
  </div>
</template>

<script>

  export default {
    components: {
	
    },
    name: 'NotFoundComponent',
    computed: {
		
    },
    data () {
      return {}
    },
  }
</script>

<style scoped>

</style>