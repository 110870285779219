<template>
	<form enctype="multipart/form-data" v-on:submit.prevent="formTask">
	<div class="row">
		<div class="col-12">
			<div class="form-group">
				<textarea required class="form-control" rows=8 v-model="task.descr" placeholder="Формулировка задачи" ></textarea>
			</div>
		</div>
		<div class="col-6 col-sm-6 col-lg-4">			
			<b-input-group class="form-group" size="sm">
				<b-input-group-prepend is-text>
					<i class="fa fa-bolt" aria-hidden="true" title="Сложность"></i>
				</b-input-group-prepend>
				
				<b-form-select :options=fibonachi v-model="task.slozhnost" size="sm">
					<template v-slot:first>
						<option :value="null" disabled>-- Выберите сложность --</option>
					</template>
				</b-form-select>
			</b-input-group>
		</div>
		<div class="col-6 col-sm-6 col-lg-4" >
			<b-input-group class="form-group" size="sm">
				<b-input-group-prepend is-text>
					<i class="fa fa-clock-o" aria-hidden="true" title="Запланированные часы"></i>
				</b-input-group-prepend>
				
				<b-form-input class="form-control" required type="number" min="0" max="999" v-model="task.time_plan" placeholder="План-часы"></b-form-input>
			</b-input-group>
		</div>
		<div class="col-12 col-sm-6 col-lg-4" >
			<b-input-group class="form-group" size="sm" prepend="Тип">				
				<b-form-select :options=types v-model="task.type_id" size="sm">
					<template v-slot:first>
						<option :value="null" disabled>-- Тип задачи --</option>
					</template>
				</b-form-select>
			</b-input-group>
		</div>
		
		<div class="col-6 col-sm-2 col-lg-2">
			<b-form-input class="form-control" id="color" v-model="task.color" style="height:32px" type="color"></b-form-input>
		</div>
		<div class="col-6 col-sm-4 col-lg-2">
			<b-input-group class="form-group" size="sm">
				<b-input-group-prepend is-text>
					<i class="fa fa-signal" aria-hidden="true" title="Приоритет"></i>
				</b-input-group-prepend>
				
				<b-form-input class="form-control" v-model="task.priority" placeholder="Приоритет"></b-form-input>
			</b-input-group>
		</div>
		
		<div class="col-12 col-sm-6 col-lg-4">
			<b-input-group class="form-group" size="sm">
				<b-input-group-prepend is-text>
					<i class="fa fa-calendar" aria-hidden="true"></i>
				</b-input-group-prepend>
				<b-form-input style="height: auto; padding: 3px 7px;" class="form-control" v-model="task.fromDate" type="date" placeholder="Дата приемки"></b-form-input>
			</b-input-group>
		</div>
		
		<div class="col-12 col-sm-6 col-lg-4">
			
			<b-input-group class="form-group" size="sm">
				<b-input-group-prepend is-text>
					<i class="fa fa-users" aria-hidden="true"></i>
				</b-input-group-prepend>
				
				<b-form-select :options=workers v-model="task.workers" size="sm">
					<template v-slot:first>
						<option :value="null" disabled>-- Исполнители --</option>
					</template>
				</b-form-select>
			</b-input-group>
		</div>
		
		<div class="col-12">
			<button type="submit" class="btn btn-success">{{nameSubmit}}</button>
		</div>
	</div>
	</form>
</template>

<script>
import axios from 'axios';

export default {
  name: "Formtaskadd",
  props: {
	linkid: Number,
	nameSubmit: String,
	formModeChg: Number,
	selTask: Object
  },
  data() {
    return {
		fibonachi: [
			{value: 1, text: 1},
			{value: 2, text: 2},
			{value: 3, text: 3},
			{value: 5, text: 5},
			{value: 8, text: 8},
			{value: 13, text: 13},
			{value: 21, text: 21},
			{value: 34, text: 34},
			{value: 55, text: 55},
			{value: 89, text: 89}
		],
		types: [
			{value: 1, text: 'без настроек (C)'},
			{value: 2, text: 'автоправило (A)'}
		],
		workers: [
			{value: 1, text: 'Исполнитель 1 (не работает)'},
			{value: 2, text: 'Исполнитель 2 (не работает)'},
			{value: 3, text: 'Исполнитель 3 (не работает)'}
		]
	}
  },
  created() {
	//this.getTeam();
  },
  computed: {
	task: function(){
		if(this.formModeChg){
			return this.selTask;
		} else {
			let fd = new Date();
			fd.setDate(fd.getDate() + 1);
		
			return {
				'descr': '',
				'fromDate': fd.toISOString().slice(0,10),
				'time_plan': 1,
				'slozhnost': 1,
				'priority': 100,
				'color': '#0f0',
				'type_id': 1,
				'workers': 0,
				'token': this.$store.state.token
			}
		}
	}
  },
  methods: {
	getTeam(){
		axios.defaults.headers.common['Authorization'] = this.$store.state.token;
		axios
			.get('http://admin.plus-ton.ru/api/list-team')
			.then(response => {
				this.team = response.data.team;
			})
			.catch(() => {
			
			});
	},
	formTask(){

		this.task.project_id = this.linkid;
		let data = this.task;
		
		axios.post('http://admin.plus-ton.ru/api/task-add', data)
			.then((res) => {
				if(this.formModeChg){
					this.$emit('chg-task', res.data);
				} else {
					this.$emit('add-task', res.data);
				}
			})
			.catch(function(){
				
			});
	}
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-dialog .btn-success {width: 100%;}
	
</style>
