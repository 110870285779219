<template>
  <div class="container">
	<div class="block-text" v-for="(article, index) in articles" :key="index">
		<div class="name"> 
			<router-link :to="{ path: '/articles/'+ article.id}" >{{article.name}}</router-link>
			<span class="date">{{article.created}}</span></div>
		<span v-html="article.descr"></span>
	</div>
  </div>
</template>

<script>
import axios from 'axios';
 
  export default {
    name: 'listarticles',
    computed: {
		
    },
    data () {
		return {
			articles: [],
			paginator: {
				limit: 20,
				page: 1
			}
		}
    },
	mounted(){
		
	},
	created(){
		this.packArticles();
	},
	methods:{
		packArticles(){
			axios.post('http://admin.plus-ton.ru/api/page-articles', this.paginator)
			.then((res) => {
				if(res.data.status == 1){
					this.articles = res.data.page
					
					this.paginator.page++
				}
			}).catch(()=>{});
			
		}
	}
  }
</script>

<style scoped>
	.name{display: flex;
  flex-direction: row;
 }
 .date{margin-left:6px; vartical-align:top; font-size:12px; color:green}
 .container{ background:#fff; padding:30px 12px 12px 12px; }
 
 .block-text{ margin-bottom:20px }
	

</style>