<template>
  <div class="interface-one">
	<template v-if="selProj.id > 0">	
		<template v-if="selProj.listStatuses.length">
			<div class="column status-mode" v-for="(packTask,index) in selProj.taskRangeStatuses" :key="index">
				<div class="column-head">{{selProj.listStatuses[index].name}} <a v-if="index == 0" @click="formAddTask" v-b-modal.modalTask class="btn-text-right" style="margin-left:12px" > <i style="vertical-align:middle" class="fa fa-plus" aria-hidden="true"></i><span style="padding-left:5px">задачa</span></a>
				</div>
				
				<div class="body-pack-tasks">
				<draggable v-model="selProj.taskRangeStatuses[index]" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false" style="min-height:200px">
				
					<div class="wrp-task-card" :class="{ 'empty-task-desc': task.id == 0 }"  v-for="task in packTask" :key="task.id">
						<template v-if="task.id == 0">
							<div style="position: absolute; top: 0px; left: 0px; height:0px; width:100%; display:none; margin:0px"></div> 
						</template>
						<template v-else>
							<div class="task-card-panel" >
								<b-dropdown size="sm" class="act-menu-task">
									<template v-slot:button-content>
										<i class="fa fa-bars" aria-hidden="true"></i>
									</template>
									<b-dropdown-item v-if="selProj.listStatuses.length-1 > index" href="#" @click="editFormTask(task)" ><i class="fa fa-pencil" aria-hidden="true"></i></b-dropdown-item>
									<b-dropdown-item href="#" @click="showTask" ><i class="fa fa-eye" aria-hidden="true"></i></b-dropdown-item>
								</b-dropdown>
								<div v-if="task.type_id > 0"><div class="stick-task" :style="{background: task.color, color: '#000'}" title="Тип задачи">{{task.type_id == 1 ? "C" : "A"}}</div> {{task.fromDate}}</div>
							</div>
							<div class="task-card-content">
								<div style="overflow: hidden; height: 86px;">{{task.descr}}</div>
							</div>
							<div class="task-card-info">
								<div class="icons" style="float:right" title="план-часы">
									<i aria-hidden="true" class="fa fa-clock-o"></i>
									<div>{{task.time_plan}}</div>
								</div>
								<div class="icons" style="float:left" title="сложность">
									<i aria-hidden="true" class="fa fa-bolt"></i>
									<div>{{task.slozhnost}}</div>
								</div>
								<div class="number-stick">
									<div class="wrp-photo"><div class="numb">{{task.number}}</div> <i class="fa fa-user" aria-hidden="true"></i></div>
								</div>
							</div>
						</template>
					</div>				
				</draggable>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="alert alert-danger">У проекта не указаны статусы</div>
		</template>
	</template>
	<div v-else style="text-align:center">
		<a v-b-modal.modalProj class="btn btn-success" >Добавить проект</a>
	</div>
	
	<b-modal id="modalTask" size="lg" :title=formTitleTask hide-footer>
		<formTaskAdd @add-task="addTask" @chg-task="editTask" :selTask="selTask" :linkid="selProj.id" :formModeChg="flagFormTask" :nameSubmit="name_submit_task" />
	</b-modal>
  </div>
</template>

<script>
 import axios from 'axios';
 import FormTaskAdd from '../components/FormTaskAdd.vue';
 import draggable from 'vuedraggable';

export default {
  name: "InterfaceOne",
  components: {
	FormTaskAdd,
	draggable
  },
  props:{
	selProj: Object,
	formModeChg: Object
  },
  data() {
    return {
		projects:[],
		flagFormTask: 0,
		selTask: {},
		
		isDragging: false,
		editable: true,
		delayedDragging: false,
		moveElement: {
			oldIndex: -1,
			oldList: -1,
			newIndex: -1,
			newList: -1
		}
	}
  },
  created() {
	
  },
  computed: {
	formTitleTask: function(){
		if(this.flagFormTask){
			return "Редактирование задачи";
		} else {
			return "Добавление задачи";
		}
	},
	name_submit_task: function(){
		if(this.flagFormTask){
			return "Сохранить изменения";
		} else {
			return "Плюс задача";
		}
	},
	
	dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
  },
  watch: {
    isDragging(newValue) {
	
      if (newValue) {
        this.delayedDragging = true; // начало переноса
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false; // завершение переноса
		
		 console.log(this.moveElement);
	
		axios.post('http://admin.plus-ton.ru/api/task-chg-status', {'val':this.moveElement.elem, 'status': this.moveElement.newList})
		.then((res) => {
			if(res.data.status != 1){
				alert('ошибка смены статуса');
			} else {
				localStorage.setItem('sel_project', JSON.stringify(this.selProj));
			}
		}).catch(()=>{});
      });
    }
  },
  methods: {
	addTask(task){ this.$emit('add-task', task); this.$bvModal.hide('modalTask');},
	editTask(){ this.$bvModal.hide('modalTask');},
	
	formAddTask(){
		this.flagFormTask = 0;
	},
	editFormTask(task){
		this.flagFormTask = 1;
		this.$bvModal.show('modalTask');
		
		this.selTask = task;
	},
	showTask(){
		console.log("Показать задачу");
	},
	shareTasksForStatuses(){
		
	},
	
	onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
	  
		this.moveElement.oldIndex = draggedContext.index;
		this.moveElement.oldList = draggedElement.status_proj;
		this.moveElement.newIndex = draggedContext.futureIndex;
		this.moveElement.newList = relatedElement.status_proj;
		this.moveElement.elem = draggedContext.element.id;
	  
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
  /*
	chgGetHist: function(history){
	
		let i = 0;
		for( ; i < this.histories.length; i++){
			if(this.histories[i].id == history.id){
				this.histories.splice(i, 1, history);
				break;
			}
		}
		i = 0;
		for( ; i < this.projHistories.length; i++){
			if(this.projHistories[i].id == history.id){
				this.projHistories.splice(i, 1, history);
				break;
			}
		}
	
		this.$bvModal.hide('modalhistory');
	},
	addHist: function(){
		this.hist = {
			name : '',
			project_id : this.selproject
		};
		this.formTitle = "Добавление истории";
		this.name_submit = "Добавить";
	},
	closeHist: function(histid){
		var i = 0;
		for( ; i < this.histories.length; i++){
			if(this.histories[i].id == histid){
				this.histories.splice(i, 1);
				break;
			}
		}
		i = 0;
		for( ; i < this.projHistories.length; i++){
			if(this.projHistories[i].id == histid){
				this.projHistories.splice(i, 1);
				break;
			}
		}
	},
	changeHist: function(histid){		
		axios.defaults.headers.common['Authorization'] = this.$store.state.token;
		axios
			.get('http://scrum.wilkins.ru/api/history-get?val='+histid)
			.then(response => {
				console.log(response.data);
				this.hist = response.data;
				this.formTitle = "Изменение истории";
				this.name_submit = "Изменить";
			})
			.catch(() => {
		
			});
	},
	moveEnd: function(){ // evt
		let data = this.histories;
		
		axios.defaults.headers.common['Authorization'] = this.$store.state.token;
		axios.post('http://scrum.wilkins.ru/api/set-priority', data)
          .then(function() {
          })
		  .catch(() => {

		  });
	},
	getAllHistories(){
		axios.defaults.headers.common['Authorization'] = this.$store.state.token;
		axios
			.get('http://scrum.wilkins.ru/api/list-history?val=0')
			.then(response => {
				this.histories = response.data.allHistory;
			})
			.catch(error => {
				this.status = error.response.data.status;
			});
	},
	getAllProjects(){
		axios
			.get('http://scrum.wilkins.ru/api/get-projects')
			.then(response => { 
			//	this.projects = Object.values(response.data).map(i => Object.values(i) )
				this.projects = response.data;
			});
	},
	getProjectHistories(id){
		axios
			.get('http://scrum.wilkins.ru/api/list-history?val='+id)
			.then(response => { 
				this.projHistories = response.data.allHistory;
			});
	},
	minimize(){
		if(this.btnmin_flag == 1){
			this.btnmin_flag = 0;
			this.activeminihistory = 'mini-history';
		} else {
			this.btnmin_flag = 1;
			this.activeminihistory = '';
		}
	},
	
	*/
  }
  
};
</script>

<style scoped>

.interface-one{color:#fff}

	.status-mode{height:200px; border-left: 10px solid #596877; padding-left: 12px; margin:8px 0px;}
	
	.body-pack-tasks{white-space: nowrap; overflow-y:hidden; overflow-x: auto;}
	
	.wrp-task-card{position:relative; display:inline-block; vertical-align:top; width: 150px; height: 150px; background: linear-gradient(to right, #F6EFD2, #CEAD78); color: #000; border-radius: 3px; margin-right:8px;}
	.wrp-task-card:hover{box-shadow:0px 0px 3px #333;}
	
	.task-card-content{white-space:normal; padding:12px; height: 98px; /* overflow: hidden; */ line-height: 14px; font-size: 12px;}
	.task-card-info{position:absolute; left:0px; bottom:0px; width:100%}
	
	.task-card-info .icons{padding: 0px 8px; text-align:center}

	.number-stick{ /* position:absolute; left:0px; bottom:0px;  width:100%; */ text-align:center;}
	.task-next-status{display:block; margin-left: 48px; margin-right: 48px;}
	
	.column-head{margin-bottom:12px; background: #596877; margin-left: -12px; padding: 1px 5px 5px 13px;}
	
	.btn-text-right{color:#efefef !important}
	.btn-text-right:hover{cursor:pointer;}
	.btn-text-right{margin-left:12px}
	
	.act-menu-task{float:right; z-index:6;  padding: 0px; line-height:1.2}
	.act-menu-task button{padding: 0px 4px; line-height: 1.6;} 
	
	.dropdown-menu.show{margin:0px}
	
	/* .wrp-task-card:hover .act-menu-task button{display:block !important}*/
	
	.wrp-photo{position: absolute; left: 50%; margin-left: -27px; top: -25px;  width: 50px;}
	.wrp-photo .numb{position: absolute; bottom: 0px; left: 0px; width: 100%; height: 16px; background: #0000009e; color: #fff; line-height: 15px;}
	.wrp-photo i{line-height: 50px; font-size: 45px;}
	
	.task-card-content:hover{z-index:3; position:relative; background:#fff}
	
	.dropdown-menu.show{z-index:2; margin:0px}
	
	.task-card-panel .dropdown-menu, .task-card-panel .dropdown-item{padding:0px; }
	.task-card-panel .dropdown-item{padding: 4px 8px;}
	.task-card-panel .dropdown-item:hover{background:#ccc}
	.task-card-panel .dropdown-menu{min-width:auto}
	
	.stick-task{background: rgb(204, 217, 227) none repeat scroll 0% 0%; float: left; padding: 0px 8px; margin-right: 4px; border-radius: 0 0 12px 0;}

.interface-one{white-space: nowrap; min-height: calc(100vh - 70px);}
.interface-one .column{display:inline-block; vertical-align:top; min-height: calc(100vh - 80px); height:100%; overflow:hidden; width:300px}

.status-mode{border-left: 2px solid #596877; padding:0 5px;}
.wrp-task-card{width:100%; height:auto; margin-bottom:6px; cursor:default;
display: block;
vertical-align: inherit;

 -ms-user-select: none; -moz-user-select: none; -khtml-user-select: none; -webkit-user-select: none; user-select: none;}

.task-card-content:hover{
	z-index: 0;
    position: inherit;
    background: inherit;
	
}
.dropdown-menu.show{transform: translate3d(10px, 23px, 0px);}


.flip-list-move { transition: transform 0.5s; }
.no-move { transition: transform 0s;}
.ghost { opacity: 0.5; background: #c8ebfb;}
.list-group { min-height: 20px;}
.list-group-item { cursor: move;}
.list-group-item i { cursor: pointer;}

.empty-task-desc{margin:0px}

.body-pack-tasks{
	height: calc(-116px + 100vh);
	overflow:hidden;
	overflow-y:auto
}



::-webkit-scrollbar-button {
background-image:url('');
background-repeat:no-repeat;
width:5px;
height:0px
}

::-webkit-scrollbar-track {
background-color:#ecedee
}

::-webkit-scrollbar-thumb {
-webkit-border-radius: 0px;
border-radius: 0px;
background-color:#6dc0c8;
}

::-webkit-scrollbar-thumb:hover{
background-color:#56999f;
}

::-webkit-resizer{
background-image:url('');
background-repeat:no-repeat;
width:4px;
height:0px
}

::-webkit-scrollbar{
width: 4px;
}

.body-pack-tasks{
	scrollbar-color: #343a40 #c3c7c8;
	scrollbar-width: thin;
}

</style>
