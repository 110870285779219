<template>
	<form enctype="multipart/form-data" v-on:submit.prevent="formProject">
	<div class="row">
		<div class="col-12">
			<div class="form-group">
				<input required class="form-control" rows=8 v-model="project.name" placeholder="Название проекта" />
			</div>
		</div>
		<div class="col-12">
			<div class="form-group">
				<b-input-group class="form-group" size="sm" prepend="Тип">				
					<b-form-select :options=types v-model="project.type_id" size="sm">
						<template v-slot:first>
							<option :value="null" disabled>-- Тип проекта --</option>
						</template>
					</b-form-select>
				</b-input-group>
			</div>
		</div>
		<div v-if="project.tasks.length == 0" class="col-12">
			<div class="form-group" style="border: 1px solid #ccc; border-radius: 3px; padding: 4px 13px;">
				<inputListSort title="Статусы задач" :lists="project.listStatuses" />
			</div>
		</div>
		<div class="col-xs-12 col-sm-6">
			<div class="form-group">
				<b-input-group size="sm">
					<b-input-group-prepend is-text>
						<i class="fa fa-calendar" aria-hidden="true"></i>
					</b-input-group-prepend>
					<b-form-input style="height: auto; padding: 3px 7px;" class="form-control" v-model="project.toDate" type="date"></b-form-input>
				</b-input-group>
				<label class="little-label">Дата старта</label>
			</div>
		</div>
		<div class="col-6">
			<div class="form-group">
				<b-input-group size="sm">
					<b-input-group-prepend is-text>
						<i class="fa fa-calendar" aria-hidden="true"></i>
					</b-input-group-prepend>
					<b-form-input style="height: auto; padding: 3px 7px;" class="form-control" v-model="project.fromDate" type="date"></b-form-input>
				</b-input-group>
				<label class="little-label">Дата финиша</label>
			</div>
		</div>
		<div class="col col-xs-12">
			<button type="submit" class="btn btn-success">{{nameSubmit}}</button>
		</div>
	</div>
	</form>
</template>

<script>
import axios from 'axios';
import InputListSort from '../components/crosspp/InputListSort.vue';

export default {
  name: "formProjectAdd",
  components: {
	InputListSort
  },
  props: {
	nameSubmit: String,
	formModeChg: Number,
	selProj: Object
  },
  data() {
    return {
		types: [
			{value: 0, text: 'Стандартный'},
			{value: 1, text: 'скрам (не реализовано)'}
		],
	}
  },
  created() {

  },
  computed: {
	project: function(){
		if(this.formModeChg){
			return this.selProj;
		} else {
			return {
				name:'',
				type_id: 0,
				toDate: '',
				fromDate: '',
				listStatuses: [
					{'id': 1, 'name': "план"},
					{'id': 2, 'name': "в процессе"},
					{'id': 3, 'name': "готово"},
				],
				tasks: []
			};
		}
	}
  },
  methods: {
	formProject(){
		let data = this.project;
		data.vval = this.$store.state.token;
	
		axios.post('http://admin.plus-ton.ru/api/project-add', data)
			.then((res) => {
				if(this.formModeChg){
					this.$emit('chg-element', res.data);
				} else {
					this.$emit('add-element', res.data);
				}
			})
			.catch(function(){
				
			});
	}
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-dialog .btn-success {width: 100%;}

.little-label{float: right; font-size: 12px; line-height: 12px; margin-bottom: 1rem; color: #5259c1;}
	
</style>
