import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from './store.js';
import BootstrapVue from 'bootstrap-vue';
import Loading from './components/lib/loading'
import CenterContainer from './components/lib/center-container'

Vue.config.productionTip = false;

Vue.component('loading', Loading)
Vue.component('center-container', CenterContainer)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");