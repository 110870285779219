<template>
  <div>
	<div>
		<b-button style="padding: 0px 2px 0px 1px; line-height: 21px; float:right; font-size: 22px;" pill variant="success" v-b-modal.modalTask><i class="fa fa-plus-circle" aria-hidden="true"></i></b-button>
		Задачи[{{tasks.length}}]
	</div>
	<template v-if="tasks.length">
	<div v-for="task in tasks" :key="task.id">
		<div class="wrp-task ui-draggable ui-droppable">
			<div class="target-drop"></div>
			<div class="task">
				<div class="numb rotatable">#task.id</div>
				<div class="name">
					<a class="task-edit" data-toggle="modal" data-target=".bs-modal-sm" data-val="task.id" href="#"><span class="glyphicon glyphicon-pencil"></span></a>
				</div>
				<div class="hours">за ч.</div>
				<div class="user">{{task.user_id}}</div>
			</div>
		</div>
	</div>
	</template>
	<template v-else>
		Для этой истории еще не создавали задач.
	</template>
	<div class="target-drop-all"></div>
  </div>
</template>

<script>
export default {
  name: "Tasks",
  props: {
    tasks: Array
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	
</style>
