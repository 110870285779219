import Vue from "vue";
import Vuex from "vuex";

import apiCall from './utils/api';

Vue.use(Vuex);

export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_ERROR = 'USER_ERROR'

export default new Vuex.Store({
  state: {
	token: localStorage.getItem('user-token') || '', 
	status: '', 
	hasLoadedOnce: false,
	status_profile: 1 //'guest'
  },
  mutations: {
	[AUTH_REQUEST]: (state) => {
		state.status = 'loading'
	},
	[AUTH_SUCCESS]: (state, resp) => {
		state.status = 'success'
		state.token = resp.token
		state.hasLoadedOnce = true
	},
	[AUTH_ERROR]: (state) => {
		state.status = 'error'
		state.hasLoadedOnce = true
	},
	[AUTH_LOGOUT]: (state) => {
		state.token = ''
	},
		
		
	[USER_REQUEST]: (state) => {
		state.status = 'loading'
	},
	[USER_SUCCESS]: (state, resp) => {
		state.status = 'success'
		Vue.set(state, 'profile', resp)
	},
	[USER_ERROR]: (state) => {
		state.status = 'error'
	},
  },
  actions: {
	[AUTH_LOGOUT]: ({commit}) => {  // , dispatch
		return new Promise((resolve) => { // , reject
			commit(AUTH_LOGOUT)
			localStorage.removeItem('user-token')
			resolve()
		})
	},
	[USER_REQUEST]: ({commit, dispatch}) => {
		commit(USER_REQUEST)
		apiCall({url: 'user/me'})
			.then(resp => {
				commit(USER_SUCCESS, resp)
			})
			.catch(() => { // resp
				commit(USER_ERROR)
				// if resp is unauthorized, logout, to
				dispatch(AUTH_LOGOUT)
			})
	},
  },
  getters:{
	isAuthenticated: state => !!state.token,
	authStatus: state => state.status,
	
	isProfile: state => state.status_profile
  }
});
